@import "../../../util/styles/fonts.scss";

.Footer {
    padding-top: 60px;
    .fa {
        font-size: 2em;
        width: 30px;
        text-align: center;
        text-decoration: none;
        color: black;
        padding-left: 0.2em;
        padding-right: 1em;
        padding-top: 0.5em;
        padding-bottom: 0.4em;
        
        &:hover {
            opacity: 0.6;
          }
          
      }

      span {
          display: block;
      }
}


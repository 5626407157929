%DIN{
    font-family: 'Din', "Capitals", serif;
    font-size: 1.5rem;
    font-weight: 600;
    font-style: normal;    
    text-transform: uppercase;
    letter-spacing: 0.03em;

    padding: 4rem 0rem 2rem 0rem;
}

%OpenSans{
    font-family: 'OpenSans', 'ProximaNova', sans-serif;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0.02em;
    line-height: 2.4em;
}

%ProximaNova {
    font-family: "ProximaNova", 'OpenSans', sans-serif;
}
@import "../../util/styles/fonts.scss";

.Info {
    @extend %OpenSans;
    margin: 1.5em;
    padding: 1.2em;

    h2 {
        font-size: 2em;
        font-weight: 600;
    }
    
    .Blurb {
        font-size: 1.3em;
        font-weight: 600;
       // margin: 1.4em;
        padding-bottom: 1em;
    }
}
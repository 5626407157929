@import "../../util/styles/fonts.scss";

.Community {
    @extend %OpenSans;
    margin: 1.5em;
    padding: 0.6em;
    h2 {
        font-size: 2em;
        font-weight: 600;
        padding-top: 0.8em;
    }
    h3 {
        font-size: 1.5em;
    }
    h4 {
        font-size: 1.2em;
        font-style: italic;
    }


}
@font-face {
    font-family: 'Back to Black';
    src:    url("../fonts/Back\ to\ Black.otf") format("otf"),
            url("../fonts/Back\ to\ Black.ttf") format("ttf");
}

@font-face {
    font-family: 'Capitals';
    src:    url("../fonts/CAPITALS.ttf") format("ttf");
}

@font-face {
    font-family: "Din";
    src:    url(../fonts/DINCondensed-Bold.woff) format("woff");
    font-weight: 600;
    font-stretch: condensed;
    font-style: normal;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/1\ Proxima\ Nova\ Condensed\ Black\ Italic.otf") format("otf");
    font-weight: 900;
    font-style: italic;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/2\ Proxima\ Nova\ Condensed\ Black.otf") format("otf");
    font-weight: 900;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/3\ Proxima\ Nova\ Condensed\ Bold\ Italic.otf") format("otf");
    font-weight: 700;
    font-style: italic;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/4\ Proxima\ Nova\ Condensed\ Bold.otf") format("otf");
    font-weight: 700;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/5\ Proxima\ Nova\ Condensed\ Extrabold\ Italic.otf") format("otf");
    font-weight: 800;
    font-style: italic;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/6\ Proxima\ Nova\ Condensed\ Extrabold.otf") format("otf");
    font-weight: 800;
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/7\ Proxima\ Nova\ Condensed\ Light\ Italic.otf") format("otf");
    font-weight: 300;
    font-style: italic;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/8\ Proxima\ Nova\ Condensed\ Light.otf") format("otf");
    font-weight: 300;
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/9\ Proxima\ Nova\ Condensed\ Regular\ Italic.otf") format("otf");
    font-weight: 400;
    font-style: italic;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/10\ Proxima\ Nova\ Condensed\ Regular.otf") format("otf");
    font-weight: 400;
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/11\ Proxima\ Nova\ Condensed\ Semibold\ Italic.otf") format("otf");
    font-weight: 600;
    font-style: italic;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/12\ Proxima\ Nova\ Condensed\ Semibold.otf") format("otf");
    font-weight: 600;
    font-style: normal;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/13\ Proxima\ Nova\ Condensed\ Thin\ Italic.otf") format("otf");
    font-weight: 100;
    font-style: italic;
    font-stretch: condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/14\ Proxima\ Nova\ Condensed\ Thin.otf") format("otf");
    font-weight: 100;
    font-style: normal;
    font-stretch: condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/15\ Proxima\ Nova\ Extra\ Condensed\ Black\ Italic.otf") format("otf");
    font-weight: 900;
    font-style: italic;
    font-stretch: extra-condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/16\ Proxima\ Nova\ Extra\ Condensed\ Black.otf") format("otf");
    font-weight: 900;
    font-style: normal;
    font-stretch: extra-condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/17\ Proxima\ Nova\ Extra\ Condensed\ Bold\ Italic.otf") format("otf");
    font-weight: 700;
    font-style: italic;
    font-stretch: extra-condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/18\ Proxima\ Nova\ Extra\ Condensed\ Bold.otf") format("otf");
    font-weight: 700;
    font-style: normal;
    font-stretch: extra-condensed;
}

@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/19\ Proxima\ Nova\ Extra\ Condensed\ Extrabold\ Italic.otf") format("otf");
    font-weight: 800;
    font-style: italic;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/20\ Proxima\ Nova\ Extra\ Condensed\ Extrabold.otf") format("otf");
    font-weight: 800;
    font-style: normal;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/21\ Proxima\ Nova\ Extra\ Condensed\ Light\ Italic.otf") format("otf");
    font-weight: 300;
    font-style: italic;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/22\ Proxima\ Nova\ Extra\ Condensed\ Light.otf") format("otf");
    font-weight: 300;
    font-style: normal;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/23\ Proxima\ Nova\ Extra\ Condensed\ Regular\ Italic.otf") format("otf");
    font-weight: 400;
    font-style: italic;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/24\ Proxima\ Nova\ Extra\ Condensed\ Regular.otf") format("otf");
    font-weight: 400;
    font-style: normal;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/25\ Proxima\ Nova\ Extra\ Condensed\ Semibold\ Italic.otf") format("otf");
    font-weight: 600;
    font-style: italic;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/26\ Proxima\ Nova\ Extra\ Condensed\ Semibold.otf") format("otf");
    font-weight: 600;
    font-style: normal;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/27\ Proxima\ Nova\ Extra\ Condensed\ Thin\ Italic.otf") format("otf");
    font-weight: 100;
    font-style: italic;
    font-stretch: extra-condensed;
}
@font-face {
    font-family: 'ProximaNova';
    src:    url("../fonts/ProximaNova/28\ Proxima\ Nova\ Extra\ Condensed\ Thin.otf") format("otf");
    font-weight: 100;
    font-style: normal;
    font-stretch: extra-condensed;
}

@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-Bold.ttf") format("ttf");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-BoldItalic.ttf") format("ttf");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-ExtraBold.ttf") format("ttf");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf") format("ttf");
    font-weight: 800;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-Regular.ttf") format("ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-Italic.ttf") format("ttf");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-Light.ttf") format("ttf");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-LightItalic.ttf") format("ttf");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-SemiBold.ttf") format("ttf");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'OpenSans';
    src:    url("../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf") format("ttf");
    font-weight: 600;
    font-style: italic;
}



@font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?subset_id=2&fvd=n3&v=3) format("woff2"),
    url(https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?subset_id=2&fvd=n3&v=3) format("woff"),
    url(https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?subset_id=2&fvd=n3&v=3) format("opentype");
    font-weight:300;
    font-style:normal;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),
    url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?subset_id=2&fvd=n4&v=3) format("woff"),
    url(https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?subset_id=2&fvd=n4&v=3) format("opentype");
    font-weight:400;
    font-style:normal;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?subset_id=2&fvd=n6&v=3) format("woff2"),
    url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?subset_id=2&fvd=n6&v=3) format("woff"),
    url(https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?subset_id=2&fvd=n6&v=3) format("opentype");
    font-weight:600;
    font-style:normal;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?subset_id=2&fvd=n7&v=3) format("woff2"),
    url(https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?subset_id=2&fvd=n7&v=3) format("woff"),
    url(https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?subset_id=2&fvd=n7&v=3) format("opentype");
    font-weight:700;
    font-style:normal;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/40ff7f/00000000000000003b9b3061/27/l?subset_id=2&fvd=i3&v=3) format("woff2"),
    url(https://use.typekit.net/af/40ff7f/00000000000000003b9b3061/27/d?subset_id=2&fvd=i3&v=3) format("woff"),
    url(https://use.typekit.net/af/40ff7f/00000000000000003b9b3061/27/a?subset_id=2&fvd=i3&v=3) format("opentype");
    font-weight:300;
    font-style:italic;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/l?subset_id=2&fvd=i6&v=3) format("woff2"),
    url(https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/d?subset_id=2&fvd=i6&v=3) format("woff"),
    url(https://use.typekit.net/af/f7d492/00000000000000003b9b3067/27/a?subset_id=2&fvd=i6&v=3) format("opentype");
    font-weight:600;
    font-style:italic;
    font-stretch: normal;
   }
  @font-face {
    font-family:"ProximaNova";
    src:url(https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?subset_id=2&fvd=i7&v=3) format("woff2"),
    url(https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?subset_id=2&fvd=i7&v=3) format("woff"),
    url(https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?subset_id=2&fvd=i7&v=3) format("opentype");
    font-weight:700;
    font-style:italic;
    font-stretch: normal;
   }
  @font-face {
    font-family:"Din";
    src:url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/l?subset_id=2&fvd=n4&v=3) format("woff2"),
    url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/d?subset_id=2&fvd=n4&v=3) format("woff"),
    url(https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/a?subset_id=2&fvd=n4&v=3) format("opentype");
    font-weight:400;
    font-style:normal;
    font-stretch: condensed;
   }

